import React, { Component } from "react";
import AboutWidget from "./FooterWidget/AboutWidget";
import SolutionWidget from "./FooterWidget/SolutionWidget";
import TeamWidget from "./FooterWidget/TeamWidget";
import Reveal from "react-reveal/Reveal";
import { BsWhatsapp, BsFacebook } from "react-icons/bs";
import "./footer.css";
class FooterTwo extends Component {
  render() {
    var { fClass, onClickService } = this.props;
    let FooterData = this.props.FooterData;
    return (
      <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
        <div className="footer_top">
          <div className="container">
            <div style={{ justifyContent: "space-between" }} className="row">
              {FooterData.CompanyWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInUp" key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget company_widget wow fadeInLeft"
                        data-wow-delay="0.2s"
                      >
                        <a
                          href="index.html"
                          className="f-logo"
                          style={{ fontSize: "20px" }}
                        >
                          {/* <img style={{width: '140px', marginLeft: '4px'}} src={require('../../img/tutorpoint.png')} alt=""/> */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{ width: "150px" }}
                              src={require("../../img/tutorpoint_logo.png")}
                              alt="logo"
                            />
                            {/* <div style={{fontFamily: '"Poppins", sans-serif', fontSize: '22px', color: '#5E2CED'}}>&nbsp;tutorpoint</div> */}
                          </div>
                        </a>
                        <div className="widget-wrap">
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Email:</span>{" "}
                            <span style={{ display: "inline-grid" }}>
                              <a
                                style={{ marginBottom: "-15px" }}
                                href="mailto:tutor@tutorpoint.in"
                                className="f_400"
                              >
                                tutor@tutorpoint.in
                              </a>{" "}
                            </span>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Phone:</span>{" "}
                            <span style={{ display: "inline-grid" }}>
                              <a href="tel:917761093194" className="f_400">
                                +91 7761093194
                              </a>
                            </span>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Address:</span> Ground & First Floor,Building No. 218, Surya Vihar Colony , Bartand,Dhanbad,
                            Jharkhand,India,826001 
                          </p>
                        </div>
                        {/* <form action="#" className="f_subscribe mailchimp" method="post">
                            <input type="text" name="EMAIL" className="form-control memail" placeholder="Email"/>
                            <button className="btn btn-submit" type="submit"><i className="ti-arrow-right"></i></button>
                        </form> */}
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              <AboutWidget ftitle="About Us" FooterData={FooterData} />
              <SolutionWidget
                ftitle="Workflow Solutions"
                FooterData={FooterData}
                onClickService={onClickService}
              />
              {/* <TeamWidget ftitle="Team Solutions" FooterData={FooterData}/> */}
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-sm-6">
                <p className="mb-0 f_400" style={{ fontSize: "14px" }}>
                  {FooterData.copywrite}
                </p>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6">
                {/* <div className="f_social_icon_two text-center">
                                    {
                                        FooterData.socialIcon.map(item =>{
                                            return(
                                                <a href="/" key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    }
                                </div> */}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <ul className="list-unstyled f_menu text-right">
                  <li>
                    <a href="/termsConditions">Terms & conditions</a>
                  </li>
                  <li>
                    <a href="/privacyPolicy">Privacy Policy</a>
                    <span style={{ marginLeft: "10px" }}>
                      <a href="/refundPolicy">| &nbsp; Refunds</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="social">
          <a
            href="https://www.facebook.com/tutorpoint1188"
            target="_blank"
            rel="noreferrer"
          >
            <div class="social-btn color-telegram">
              <BsFacebook class="icons8-telegram-app" />
              <p class="order-1 google-font margin-telegram">Facebook</p>
            </div>
          </a>
          <a
            href="https://www.instagram.com/tutorp0int/"
            target="_blank"
            rel="noreferrer"
          >
            <div class="social-btn color-instagram">
              <div class="icons8-instagram"></div>
              <p class="order-1 google-font margin-instagram">Instagram</p>
            </div>
          </a>
          <a href="https://wa.me/917761093194" target="_blank" rel="noreferrer">
            <div class="social-btn color-whatsapp">
              <BsWhatsapp class="icons8-whatsapp" />
              <p class="order-1 google-font margin-instagram">Whatsapp</p>
            </div>
          </a>
        </div>
      </footer>
    );
  }
}
export default FooterTwo;
